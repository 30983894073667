
import ResultSet from "@/models/ResultSet"
import CallHistoryService from "@/services/CallHistoryService"
import useTimeUtils from "@/utils/time"
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import { state } from "@/utils/state"
import LinkLabel from "../navigation/LinkLabel.vue"
import usePhoneUtils from "@/utils/phone"
import EmployeeService from "@/services/EmployeeService"
import ContactService from "@/services/ContactService"
import Employee from "@/models/Employee"

export default {
  name: "CallsCollection",
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  components: { LinkLabel },
  setup(props: any) {
    let part: number = 0
    const { absoluteDateTime, absoluteTime, absoluteDate, distanceBetweenDates } = useTimeUtils()
    const { formatPhone } = usePhoneUtils()
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = ref()
    const callHistoryService = new CallHistoryService()
    const employeeService = new EmployeeService()
    const contactService = new ContactService()
    const loading = ref(true)
    const filter = ref("")
    const sort = ref("")
    const calls = reactive(new Map())
    const employees = reactive(new Map())
    const contacts = reactive(new Map())
    const unknownContacts = reactive([])

    loadPart()

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }
      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }
      window.removeEventListener("resize", checkVisible)
    })

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    employeeService.getAll(null, 0, "", "", 1000).then((result: ResultSet<Employee>) => {
      employees.clear()
      result.rows.forEach((row: any) => {
        employees.set(row.id, row)
      })
    })

    function loadPart() {
      loadingPart.value = true
      callHistoryService
        .getCallHistory(part, "time,desc", props.organization?.id, null, true, true)
        .then((data) => {
          onPartLoaded(data)
        })
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome
      if (result.rows.length == 0) {
        return
      }
      part++
      result.rows.map(function (element) {
        calls.set(element.id, element)
        if (
          element.organizationId &&
          !contacts.get(element.phone) &&
          !unknownContacts.includes(element.phone)
        ) {
          contactService.getContactByPhone(element.phone).then((res: any) => {
            if (res) {
              contacts.set(element.phone, res)
            } else {
              unknownContacts.push(element.phone)
            }
          })
        }
      })
      nextTick().then(() => {
        checkVisible()
      })
    }

    function checkVisible() {
      if (loadedAllData.value) return
      if (loadingPart.value) return
      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart()
      }
    }

    function reload() {
      loadingPart.value = true
      part = 0
      loadedAllData.value = false
      calls.clear()
      loadPart()
    }

    function getEmployeeInitialsOfId(userId: string) {
      const firstInitial = employees.get(userId)?.firstName?.charAt(0)
      const lastInitial = employees.get(userId)?.lastName?.charAt(0)
      return (firstInitial ? firstInitial : "") + (lastInitial ? lastInitial : "")
    }

    function isToday(date: Date) {
      const today = new Date()

      if (today.toDateString() === date.toDateString()) {
        return true
      }

      return false
    }

    return {
      loadedAllData,
      loadingPart,
      loading,
      calls,
      absoluteDateTime,
      formatPhone,
      state,
      employees,
      getEmployeeInitialsOfId,
      absoluteTime,
      absoluteDate,
      isToday,
      distanceBetweenDates,
      contacts,
    }
  },
}
